$white: #fff;
$black: #111;
$gray: #cdd0c9;
$primary: #97d144;
$secondary: #008000;
$tertiary: #041847;

$footerNavHeight: 420px;

@font-face {
  font-family: "figtree";
  src: url("./assets/fonts/Figtree-Regular.ttf");
}

div.js-code-highlight {
  white-space: pre;
}

.highlight.hljs {
  font-size: 1.5rem;
  overflow: scroll;
}

.js-actions-panel {
  display: none;
}

// utility classes
.button {
  display: inline-block;
  padding: 0 1rem;
  font-weight: bold;
  text-decoration: underline;
}

.button.border {
  text-decoration: none;
}

.border {
  overflow: hidden;
  border-radius: 0 2rem 0 2rem;
  border: 4px solid $tertiary;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.clearFloat {
  clear: both;
}

.margin {
  margin: 20px;
}

.picture {
  border-radius: 0 2rem 0 2rem;
  border: 4px solid $secondary;
}

.boxShadow {
  box-shadow: $black 0 0 0 2px;
}

.borderRadius {
  border-radius: 0 2rem 0 2rem;
}
//

* {
  margin: 0;
  padding: 0;
}

p {
  margin: 20px 0;
}

ul {
  margin-left: 20px;
}

hr {
  margin: 20px 0;
}

html, body {
  padding: 0;
  margin: 0;
}

body {
  background-color: #041131;
  color: $white;
  overflow-y: scroll;
  overflow-x: hidden;
}

a, a:active, a:visited {
  color: $white;
}

.app {
  display: block;
  position: relative;
  height: 100%;
  text-align: center;
  width: 100%;
  max-width: 1024px;
  margin: auto;
  font-size: 1.8rem;
  overflow-x: hidden;
}

.logo {
  display: block;
  position: absolute;
  z-index: 3;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
}

.header {
  position: fixed;
  z-index: 4;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.headerOverlay {
  position: relative;
  z-index: 1;
}

.subNav {
  position: absolute;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
}

.subNavLinks {
  width: 400px;
  display: flex;
  flex: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 54px;
  justify-content: space-around;
  height: 30px;

  a {
    flex: 1;
    text-decoration: none;
    color: $black;
    line-height: 30px;
  }

  a:hover {
    background: radial-gradient($primary, $primary, transparent, transparent);
  }
}

.main {
  margin: -40px auto 0;
  box-sizing: border-box;
  text-align: left;
  max-width: 780px;
  position: relative;
  background-color: #242424;
  color: $white;
  height: 100%;
  z-index: 0;
  border-left: 2px solid $primary;
  border-right: 2px solid $primary;
  box-shadow: $primary 0 20px 10px;
  min-height: 100vh;

  img {
    max-width: 100%;
  }
}

.mainContent {
  padding: 160px 20px;
  position: relative;
  height: 100%;
  z-index: 0;
}


.footer {
  position: fixed;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.footerNav {
  position: absolute;
  z-index: 1;
  bottom: 50px;
  width: 640px;
  height: $footerNavHeight;
  left: 50%;
  transform: translateX(-50%);
  background: url('assets/imgs/layoutImgs/footerNavHeaderBg.png') center;
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 20px;
  transition: bottom 200ms;
}

.footerNav.closed {
  bottom: -$footerNavHeight + 94px;
}

.footerNavTrigger {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  width: 40px;
  z-index: 3;
}

.footerNavMain {
  box-sizing: border-box;
  padding: 20px;
  margin: -54px auto 0 auto;
  width: 98%;
  height: 100%;
  background-color: #222;
  color: $white;
  border: 1px solid $secondary;
}

.footerNavHeader {
  position: relative;
  top: -40px;
}

.footerBase {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  z-index: 2;
}

.siteLoading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $tertiary;
  z-index: 1000;

  p {
    width: 200px;
    display: inline-block;
    border-radius: 0 2rem 0 2rem;
    background-color: $white;
    color: $black;
    padding: 1rem;
  }
}

.itemLoading {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  color: $white;
  justify-content: center;
  align-items: center;

  img {
    position: relative;
    width: 26px;
    z-index: 1;
  }

  .itemLoadingBackdrop {
    position: absolute;
    display: flex;
    background-color: #f1f2f3;
    color: $white;
    padding: 15px;
    z-index: 0;
    border-radius: 0 1rem;
    border-width: 2px;
  }
}

.preloadImage {
  display: block;
  position: relative;

  img {
    display: block;
  }

  .loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $black;
    color: $white;
    border: 1px solid red;
  }
}

.post {
  //cursor: pointer;
}

.box {
  margin: 40px 0 20px 0;
}

.box:first-child {
  margin-top: 0;
}

.box.left {
  border-radius: 0 40px 0 0;
  padding: 1rem;
  margin-left: -20px;
  background: linear-gradient(330deg, grey, $white);
  color: #222;
}

.box.left.green {
  background: linear-gradient(330deg, $secondary, $primary);
}

.box.right {
  border-radius: 40px 0 0 4px;
  padding: 1rem;
  margin-right: -20px;
  background: linear-gradient(330deg, $white, $gray);
  color: #222;
}

.box.right.green {
  background: linear-gradient(30deg, $secondary, $primary);
}

.box.full {
  margin: auto -20px;
  padding: 1rem;
  color: $white;
}

.box.full.white {
  margin: auto -20px;
  padding: 1rem;
  background: linear-gradient(90deg, $white, $gray);
  color: $black;

  a {
    color: $black;
  }
}

.box.full.green {
  background: linear-gradient(270deg, $primary, $secondary);
  color: $black;
}

.textLarge {
  font-size: 2rem;
}

.inline {
  display: inline-block;
}

.flipBook {
  height: 340px;
  max-width: 800px;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  border: 1px solid $primary;
  justify-content: stretch;

  &.bottom {
    height: 500px;
    flex-flow: column;

    .screen {
      width: auto;

      & > * {
        max-height: 100%;
      }
    }

    .items {
      flex-flow: row nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  .screen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 4;
    overflow: hidden;

    & > div {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .items {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    list-style-type: none;
    overflow-y: scroll;
    margin-left: 4px;
    justify-items: stretch;
    background-color: $black;
    border: 4px solid $primary;

    & > * {
      flex: 0 0 auto;
      border: 1px solid grey;
      border-radius: 1rem;
      overflow: hidden;
      background-color: $black;
      cursor: pointer;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.imageWithOverlayText {
  span {
    display: none;
  }
}

.screen .imageWithOverlayText {
  display: flex;
  position: relative;

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 60px;
    background-color: rgba($white, 0.6);
    color: $black;
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: bold;

    p {
      margin: 0;
    }
  }
}